.section-distance > * {
    margin-bottom: 15px;
}

h2 {
    color:var(--primary_text);
}

.button-gorup .btn{
}

.location-away-text{
    margin-top: 20px;
    color: var(--primary_text);
}

.distance-text{
    color: var(--primary_text);
}

.btn-different{
    padding: 8.5px 20px !important;
}

.btn-different:hover{
    background: var(--secondary);
    color: var(--secondary_text);
}
