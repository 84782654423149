section.brand {
    padding-top: 0px;
    margin-bottom: calc(var(--gap) * 2);
    width: 100%;
    max-width: 525px;
    margin-left: auto;
    margin-right: auto;
}

.brand-logo {
    width: 100%;
    height: auto;
    display: block;
    margin: 0 auto ;
}

.brand-logo img {
    display: block;
    margin: 0 auto;
    width: 100%;
}
