.age-confirmation{
    position: relative;
    color: var(--primary_text);
    padding: 0 !important;
}

.age-confirmation form {
    /* max-width: 370px; */
    margin: 0 auto;
    padding: 0px 25px   ;
}

.age-confirmation .form-row {
    display: flex;
}

.age-confirmation .select-wrapper {
    flex: 1;
    margin : 12px 0px;
}
.age-confirmation .date-fields {
    justify-content: space-between;
}
.age-confirmation .date-fields .select-wrapper {
    max-width: calc(33% - 10px);
}

.age-confirmation .date-fields .select-wrapper:nth-child(3) {
    max-width: calc(38% - 10px);
}

.age-confirmation .form-row select {
    flex: 1;
}
.age-confirmation form,
.age-confirmation select {
    color: var(--form_text_color);
}

.btn{
    font-family: var(--font_button);
}



@media (max-width:1280px){

    .age-confirmation form{
        padding: 0px 0px;
    }
}
