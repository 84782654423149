main > section.locaiton-interface {
    padding: 0 5px;
}

.locaiton-interface {
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: top;
}

.locaiton-form {
    display: flex;
    /* margin: 0 15px 20px; */
    position: relative;
    z-index: 1000;
    color: var(--secondary_text);
}

.locaiton-form .input-wrapper {
    display: inline-block;
    vertical-align: top;
    position: relative;
}

.locaiton-form .input-wrapper + .input-wrapper {
    margin-left: 20px;
    width: 100%;
}

.locaiton-form .input-wrapper .locate-btn {
    width: 50px;
    height: 50px;
    border: none;
    padding: 10px;
    background-color: var(--secondary);
    color: var(--form_text_color);
}

/* .locaiton-form .input-wrapper .locate-btn path {
    fill: var(--secondary_text);
    color: var(--secondary_text);
} */

.locaiton-form .input-wrapper input[type='text'] {
    padding-right: 50px;
    position: relative;
    z-index: 3;
    box-shadow: none;
    line-height: 50px;
    font-size: 1em;
    border-radius: 4px;
    background-color: var(--form_color);
}

@media (max-width: 350px) {
    .locaiton-form .input-wrapper input[type='text'] {
        max-width: 200px;
    }
}

.locaiton-form .input-wrapper button[type='submit'] {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 4;
    border: 0;
    font-size: 1.1em;
    border-radius: 0 4px 4px 0;
    background-color: var(--form_color);
    color: var(--form_color);
    border-left: solid 1px var(--form_text_color);
    display: flex;
    justify-content: center;
    align-items: center;
}

section.locations {
    margin: 0;
    overflow: hidden;
    border-radius: 4px;
    z-index: 0;
}

#locationIcon{
    fill: var(--secondary_text);
}

.map-wrapper {
    position: relative;
    width: 100%;
    height: calc(100vw - 40px);
    max-height: 500px;
    overflow: hidden;
    max-width: 100%;
    border-radius: 4px;
    margin: 0 auto;
    z-index: 0;
}

.map-wrapper > div {
    height: 110% !important;
}

@media (min-width: 1280px) {
    .map-wrapper {
        height: 500px;
    }
}

.locations-list {
    position: relative;
    list-style: none;
    text-align: left;
    margin: calc(var(--gap) * -2) 0 0 0;
    padding: 0 var(--gap);
    color: var(--form_text_color);
    display: inline-block;
    width: 100%;
}

.locations-list li {
    padding: 18px 20px;
    overflow: hidden;
    cursor: pointer;
    background-color: var(--form_color);
    border-radius: 4px;
    margin-bottom: 18px;
    text-align: center;
}

.locations-list li:last-child {
    margin-bottom: 0;
}

.magnify{
    color : var(--form_text_color);
}

.locations-list li h3 {
    font-size: 1.125em;
    margin: 0 0 10px;
}
.locations-list li p {
    margin: 0;
    font-size: 0.875em;
    line-height: 1.5;
    color: var(--black);
}

/* .locations-list li:nth-child(odd) {
    background-color: var(--brand-btn-color);
} */

.modal-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--primary_alpha_65);
    flex: 1;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    height: 100%;
    width: 100%;
    display: flex;
    backdrop-filter: blur(4px);
}

.modal {
    background-color: var(--form_color);
    padding: 30px;
    text-align: left;
    margin: 20px;
    position: relative;
    border-radius: 8px;
    text-align: center;
    width: calc(100% - 70px);
    max-width: 360px;
    box-shadow: 0px 5px 15px rgb(0 0 0 / 10%);
}

.modal h3 {
    font-size: 18px;
    margin: 0 0 10px 0;
    font-weight: bold;
    color: var(--form_text_color);
}

.modal p {
    margin: 0;
    font-size: 1em;
    margin: 0 0 20px 0;
    line-height: 1.5;
    color: var(--form_text_color);
}

.modal button {
    display: block;
    margin: 0 auto;
}

.modal-close {
    background-color: var(--primary);
    color: var(--form_color);
    position: absolute;
    top: -25px;
    right: -25px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    cursor: pointer;
}

.google-places-autocomplete__suggestions {
    padding: 20px;
    font-style: italic;
}

.google-places-autocomplete__input {
    padding: 0 20px;
    color: var(--form_text_color);
    /* font-weight: bold; */
}
.google-places-autocomplete__suggestions-container {
    margin-top: -12px;
    box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.3);
    background-color: var(--form_color);
    position: absolute;
    top: 100%;
    right: 0;
}

.google-places-autocomplete__suggestion {
    font-size: 0.876em;
    color: var(--form_text_color);
    box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 15px 20px;
    background-color: var(--form_color);
}

.google-places-autocomplete__suggestion:hover {
    background-color: var(--form_color);
    box-shadow: none;
    cursor: pointer;
}

.location-result {
    padding: 20px 35px;
    margin-bottom: 40px;
}
