.checkbox-container {
    text-align: left;
    display: inline-block;
    position: relative;
    padding-left: 45px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 16px;
    font-size: 16px;
}

.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: transparent;
    border: solid 2px var(--primary_text);
    border-radius: 4px;
}

.checkmark:after {
    content: '';
    position: absolute;
    display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}

.checkbox-container .checkmark:after {
    background-color: var(--primary_text);
    width: 14px;
    height: 14px;
    top: 3px;
    left: 3px;
    border-radius: 2px;
}
