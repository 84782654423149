.swipe-section {
    background-color: var(--form_color);
    padding: 30px 0 0;
    color: var(--primary);
    width:100%;
    /* position:  relative !important;  */
    border-radius: 4px;
    padding-bottom:15px;
    min-height:300px;
}





.swipe-section img {
    width: 100%;
}

.swipe-icon {
    width: 330px;
    display: block;
    margin: 0 auto;
}

.swipe-icon path {
    fill: var(--primary);
}



.swipe-text{
    color: var(--form_text_color);
}

.swipe-section .page-slug h2 {
    color: var(--primary);
    font-size: 32px;
}

.swipe-img-section{
    position: relative;
    line-height :0;
}

.negativeIcon{
    position:static;
    left: 0;
    margin: 0 0 0 0;
    width: 100%;
    height:auto;
    padding : 0px 15px;
}

.positiveIcon{
    position:absolute;
    left: 0;
    margin: 0 0 0 0;
    width: 100%;
    height:auto;
    clip-path: inset(0 90% 0 0);
    padding : 0px 15px;
    z-index: 100;
}

#swipeInput{
    -webkit-appearance: none;
    width:100% !important;
    position:absolute;
    top:50%;
    bottom:50%;
    left:0;
    right: 0;
    padding-right: 15px;
    padding-left: 15px;
    border-radius: 2px;
    padding-top: 10%;
    z-index: 102;
    background:unset;
}

#myClip{
    overflow-x: hidden;
}

 #sliderHand{
    width:100% !important;
    position:absolute;
    padding-left: 15px;
    top:50%;
    bottom:50%;
    right: 0;
    border-radius: 2px;
    /* padding-top: 10%; */
    z-index: 102;
    background:unset;
    text-align: left;
 }
  
  
  #swipeInput::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    min-height:140px;
    width: 105px;
    margin-right: 100px;
    margin-left: 0px;
    background-image: url(../../assets/images/hand.svg);
    background-position: top top;
    background-size: contain;
    background-repeat: no-repeat;
  }


  #swipeInput::-moz-range-track{
    -webkit-appearance: none;
    height: 100% !important;
    width:100% !important;
    position:absolute;
    padding-right: 0px;
     padding-left: 0px;
    top: 0;
    left:0;
    right: 0;
    bottom: 0;
    border-radius: 2px;
    padding-top: 30%;
    z-index: 102;
    background:unset;
  }

  #swipeInput::-moz-range-thumb {
     -webkit-appearance: none;
     background-color: unset;
     padding-right: 0px;
     padding-left: 0px;
    border: none;
    height: 130px;
    width: 100px;
    margin-right: 100px;
    margin-left: 0px;
    background-image: url(../../assets/images/hand.svg);
    background-position: top top;
    background-size: contain;
    background-repeat: no-repeat;
  }

