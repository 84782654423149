footer#site-footer {
    width: 100%;
    max-width: 525px;
    margin: 0 auto;
    font-size: 0.8rem;
    color:var(--primary_text);
    padding: 0 15px 0 15px;

}

.footer-image{
    padding-bottom: 24px;
    width: 100%;
}

footer > div {
    margin: 16px 8px 16px;
    padding: 16px;
    border-radius: 4px;
    background-color: var(--primary_alpha_65);
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: var(--primary_text);
    box-sizing: border-box;
}

footer p:first-child {
    margin-bottom: var(--gap);
}

footer#site-footer a {
    color: var(--primary_text);
}

footer#site-footer p {

    color: var(--primary_text);
}

.faqs-text {
    /* margin-left: 40px;
    margin-right: 40px; */
    /* margin-bottom: 20px; */
}

.copyright {
    font-family: var(--font_body);
    font-weight: bold;
    color: var(--primary_text);
}

/* @media (min-width: 1900px) {
    footer#site-footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
    }
} */
