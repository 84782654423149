@import url("../fonts/heineken/style.css");
:root {
    --gap: 16px;

    --primary_text: #ffffff;
    --primary_text_alpha_08: rgba(255, 255, 255, 0.08);
    --primary_text_alpha_5: rgba(255, 255, 255, 0.5);
    --secondary_text: #000000;
    --form_color: #ffffff;
    --form_text_color: #000000;

    --green: #25d366; /* WhatsApp color - should be static */
}

* {
    box-sizing: border-box;
}

::selection {
    background-color: var(--primary_text);
    color: var(--primary);
}

form ::selection,
.locations-list ::selection,
.modal ::selection {
    background-color: var(--primary);
    color: var(--primary_text);
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 4px;
    border-color: transparent;
    font-size: 16px;
    border: 0;
    height: 50px;
}

select::-ms-expand {
    display: none;
}

html {
    background-color: var(--primary);
}

body {
    font-family: var(--font_body);
    font-size: 16px;
    font-style:normal;
    line-height: 1.5;
    margin: 0 auto;
    background-repeat: repeat-y;
    background-size: 100% auto;
    color: var(--primary_text);
    overflow: hidden;
    overflow-y: auto;
    /* max-width: 1150px; */
}

p b {
    font-size: 1.125em;
}

.page-loading {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--primary);
    z-index: 9999999;
    opacity: 1;
}

.animated-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--font_title);
    font-weight: bold;
    line-height: 1.2;
    font-style: normal;
    margin-top: 0;
    margin-bottom: 40px;
}

h1 {
    font-size: 32px;
}

h2 {
    font-size: 22px;
    line-height: 1.3;
    text-transform: none;
}

main {
    text-align: center;
    max-width: 525px;
    margin: 0 auto;
    width: 100%;
}

main + main {
    margin-top: 15px;
}

@media (min-width: 1280px) {
    main.container-large {
        max-width: 770px;
    }
}

main > section {
    /* padding: 0 30px; */
    margin: 0 var(--gap) calc(var(--gap) * 3);
}

main > section > *:last-child {
    margin-bottom: 0;
}

.with-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center center;
    z-index: -1;
}

.page-wrapper.has-bg main > section,
.page-wrapper.has-bg main > footer {
    margin: 0 var(--gap) var(--gap);
    padding: calc(var(--gap) * 1.5) var(--gap);
}

.page-wrapper.has-bg main > section.campaign-logo,
.page-wrapper.has-bg main > section:last-child {
    margin-bottom: calc(var(--gap) * 2);
}

.page-wrapper.has-bg main > section.campaign-logo {
    padding: 0;
}

.page-wrapper > main:first-child {
    margin-top: calc(var(--gap) * 2);
}

.transparent-wrapper {
    background-color: var(--primary_alpha_65);
    backdrop-filter: blur(4px);
}
main.transparent-wrapper > section {
    padding: 0 15px;
}

.transparent-wrapper > p,
.transparent-wrapper > .page-slug {
    margin-left: 15px;
    margin-right: 15px;
}

main > section.follow-us {
    margin: 30px 0 10px;
    padding: 10px 0;
    background-color: transparent;
}

section.follow-us h3 {
    margin-bottom: 15px;
    display: none;
}

section.follow-us ul.inline-list {
    margin-bottom: 0;
}

p {
    margin-top: 0;
    margin-bottom: 15px;
}

a {
    color: inherit;
}

a.btn {
    text-decoration: none;
}

img {
    max-width: 100%;
    height: auto;
}

figure {
    margin: 20px 0;
}

input {
    display: block;
    width: 100%;
    background-color: var(--form_color);
    color: var(--form_text_color);
    margin: 0 0 var(--gap) 0;
    padding: 0 20px;
    line-height: 50px;
    font-size: 22px;
    border: none;
    border-radius: 4px;
}

textarea {
    font-size: 22px;
    padding: 20px;
    border-radius: 4px;
    border: none;
    background-color: var(--primary_text);
}

@media (min-width: 1200px) {
    input {
        margin: 0 0 calc(var(--gap) * 1.5) 0;
    }
}

.input-wrapper:not(.input-block) {
    text-align: left;
}

::placeholder {
    color: var(--form_text_color);
    opacity: 0.8;
}

.parent,
.container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.desktop-view {
    flex: 1;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.desktop-message {
    color: var(--primary_text);
    font-style: italic;
    font-size: 2em;
}

.btn {
    max-width: 225px;
    display: inline-block;
    font-size: 22px;
    border-radius: 4px;
    color: inherit;
    line-height: normal;
    font-weight: bold;
    font-family: var(--font_button);
    
}

.btn:last-child {
    margin-bottom: 0;
}

.btn-mb-none,
.sign-up-message .btn.btn-mb-none {
    margin-bottom: 0;
}

.btn i + span {
    margin-left: 15px;
}

.btn-wide {
    max-width: none;
    width: 100%;
}

.btn-outline {
    background-color: transparent;
    color: var(--primary_text);
    border: solid 2px var(--primary_text);
    padding: 12.5px 20px;
    min-width: 225px;
    transition: all 0.4s ease;
}

.btn-outline:hover {
    background-color: var(--primary_text);
    color: var(--secondary_text);
    border-color: transparent;
}

.btn-brand {
    background-color: var(--secondary);
    padding: 10.5px 20px;
    border-color: transparent;
    border: 0;
    min-width: 225px;
    color: var(--secondary_text);
    box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.25);
}

.btn-capitalize {
    text-transform: capitalize;
}

.btn-primary {
    background-color: var(--primary);
    box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.25);
}

.btn-secondary {
    background-color: var(--secondary);
    color: var(--secondary_text);
    padding: 6px 20px;
    border-color: transparent;
    min-width: 220px;
}

.btn-block {
    width: 100%;
    max-width: 100%;
}

.btn-whatsapp {
    padding: 12px 20px;
    min-width: 225px;
    background: var(--green);
    border: 0;
    box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    margin-top: 20px;
}

@media (max-width: 350px) {
    .btn-brand {
        min-width: 0;
    }
    

    input,
    select {
        font-size: 16px;
    }
}

.select-wrapper {
    position: relative;
}

.select-wrapper select {
    width: 100%;
    padding: 15px 10px;
    background-color: var(--form_color);
}

.select-wrapper::before {
    content: '\f078';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 50px;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    background-color: var(--form_color);
    font-family: 'Font Awesome 5 Pro';
    display: flex;
    font-size: 14px;
    font-weight: 900;
    border-radius: 0 4px 4px 0;
    color: var(--form_text_color);
    border-left: solid 1px var(--primary);
}

.iPhone .select-wrapper::before,
.mac-os .select-wrapper::before {
    top: 0px;
    bottom: 0px;
}

@media (max-width: 325px) {
    .select-wrapper::before {
        width: 32px;
        font-size: 14px;
        padding-top: 7px;
    }
}

ul.inline-list {
    list-style: none;
    display: flex;
    justify-content: center;
    padding: 0;
    flex-wrap: wrap;
}

ul.inline-list li {
    margin: 0 15px 15px;
}

ul.inline-list li a {
    display: block;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border-radius: 2px;
}

ul.inline-list li a:hover {
    text-decoration: none;
}

ul.inline-list li a i {
    color: var(--form_color);
    font-size: 1.4em;
}

.section-lite {
    position: relative;
    overflow: hidden;
    /* border-radius: 8px; */
    /* background-color: rgba(0, 0, 0, 0.6); */
    /* backdrop-filter: blur(8px); */
}

.section-lite .section-lite,
.transparent-wrapper .section-lite.card,
.transparent-wrapper-top .section-lite.card {
    margin: 0 15px 20px;
    padding: 20px 15px;
    /* background-color: rgba(255, 255, 255, 0.1); */
    /* backdrop-filter: blur(8px); */
    /* color: rgba(255, 255, 255, 0.8); */
}

.section-lite .section-lite.card p {
    margin-bottom: 0;
}

.section-lite.card > .section-lite.card {
    position: relative;
    overflow: hidden;
    z-index: 2;
}

.section-lite.card > .section-lite.card h3 {
    margin-bottom: 20px;
}

.section-lite.card.location-result {
    margin-left: 15px;
    margin-right: 15px;
    padding: 20px 15px;
    position: relative;
    z-index: 2;
    overflow: hidden;
}

.section-lite.card.location-result h3 {
    margin-bottom: 20px;
    color: var(--form_text_color);
}

.section-lite.card.location-result p {
    margin-bottom: 0;
    color: var(--form_text_color);
}

.section-danger {
    background-color: var(--primary);
    color: var(--primary_text);
}

.section-danger > *:last-child {
    margin-bottom: 0;
}

.section-padding-small {
    padding: 20px 35px;
    margin-bottom: 30px;
}

.section-padding-top {
    padding-top: 20px;
}

.section-padding-vertical {
    padding: 20px 0;
}

.text-center {
    text-align: center;
}

.content {
    margin-bottom: 40px;
    padding: 0 15px;
}

.grecaptcha-badge { 
    visibility: hidden;
}

.show-badge div .grecaptcha-badge{
    visibility: visible;
}


.devider-txt {
    margin: 15px 0;
}

form .checkboxes-wrapper {
    margin-top: 30px;
    flex-basis: 100%;
}

.register-form .checkboxes-wrapper p.form-error {
    display:inline-table;
    background-color: var(--primary);
    margin: 0 0 0 0;
    text-align: center;
    padding: 6px;
    border-radius: 4px;
    font-weight: bold;
}

@media (min-width: 1200px) {
    .register-form .checkboxes-wrapper p.form-error {
        color: var(--primary_text);
        margin-right: 0;
    }
}

.form-validation-error {
    /* position: absolute;
    bottom: 0;
    right: 0; */
    font-size: 13px;
    color: var(--primary_text);
    
    margin: calc(var(--gap) * -0.5) 0 calc(var(--gap) * 0.5);;
    text-align: left;
}

.form-validation-error-out {
    margin-bottom: 14px;
    font-size: 13px;
    color: var(--primary_text);
}

.form-validation-error-bottom {
    /* position: absolute;
    bottom: -14px;
    right: 0; */
    font-size: 13px;
    color: var(--primary_text);
    /* margin: 0; */

    margin: calc(var(--gap) * -0.5) 0 0;
    text-align: left;
}

.p-relative {
    position: relative;
}

.p-static {
    position: static;
}

.underline {
    text-decoration: underline;
}

.temp-loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    background-color: var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
}

.mb-35 {
    margin-bottom: 35px;
}

.mb-40 {
    margin: 0 0 40px 0 !important;
}

/* loader */

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid var(--secondary_text);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--secondary_text) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* inline loader */

.inline-loading {
    display: flex;
    justify-content: center;
    align-items: center;
}

.inline-loading .lds-ring {
    width: 30px;
    height: 30px;
    display: none;
}

.inline-loading .lds-ring div {
    width: 24px;
    height: 24px;
    margin: 3px;
    border-width: 3px;
}

.loading .inline-loading .lds-ring {
    display: block;
}

.loading span {
    display: none;
}

.transparent-wrapper {
    position: relative;
    padding: 20px;
    /* margin: 0 10px 40px; */
    margin: 0 0 10px; /* negative-space test */
    overflow: hidden;
    border-radius: 8px;
    /* background-color: rgba(0, 0, 0, 0.6); */
    /* backdrop-filter: blur(8px); */
}

@media (min-width: 524px) {
    .transparent-wrapper {
        /* margin: 0 auto 40px; */
        margin: 0 auto 10px; /* negative-space test */
    }
    
}

@media (min-width: 672px) {
    .transparent-wrapper {
        /* margin: 0 auto 40px; */
        margin: 0 auto 10px; /* negative-space test */
    }
    
}

.transparent-wrapper-top {
    margin: 0 15px;
    padding: 20px 0;
    position: relative;
    overflow: hidden;
    border-radius: 8px 8px 0 0;
    backdrop-filter: blur(4px);
    background-color: var(--primary_color_opacity);
}

.transparent-wrapper-bottom {
    margin: 15px 15px;
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
    overflow: hidden;
    border-radius: 0 0 8px 8px;
    backdrop-filter: blur(4px);
    background-color: var(--primary_color_opacity);
}

.brand-color {
    color: var(--primary);
}



.mb0 {
    margin-bottom: 0;
}

.follow-us {
    color: var(--primary);
}

.the-content {
    padding: 0 22px;
    line-height: 1.6;
    font-size: 1em;
    text-align: left;
}

.the-content h2 {
    font-size: 1.125em;
    margin-top: 3rem;
    margin-bottom: 1rem;
}

.the-content h2.block_5 {
    margin-top: 3rem;
    margin-bottom: 1rem;
    font-size: 1.125em;
}

#react-google-places-autocomplete-input{
    height: 50px;
}



.swipe-with-icons {
    display: flex;
    flex-direction: column;
    justify-content: center;
}




.transparent-wrapper-top ~ .locaiton-interface > div {
    position: relative;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(8px);
}

ul.list-social-media.social-shares li {
    margin-left: 5px;
    margin-right: 5px;
}

ul.list-social-media.social-shares li button {
    width: 48px;
    height: 48px;
}

.site-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
}

.code-wrapper {
    background-color: #fff;
    border-radius: 4px;
    padding: 15px;
    margin: 30px 0px 38px;
    line-height: 0;

}

.code-wrapper p {
    color: #000000;
    font-size: 1.2em;
    letter-spacing: 6px;
    margin: 0;
    padding: 0;
    white-space: normal;
    word-break: break-all;
    margin-right: -6px;
    font-weight: bold;
    line-height: 1!important;
}

.code-wrapper canvas{
    margin-bottom: 15px;
}

.remaining-time {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 40px;
    color: var(--primary);
    font-size: 1.8em;
    margin-bottom: 20px;
    border-radius: 4px;
}

.remaining-time > div {
    background-color: var(--form_color);
    color: var(--form_text_color);
    margin: 0 5px;
    padding-left: 30px;
    letter-spacing: 30px;
    position: relative;
    width: 100px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding-bottom: 3px;
}

.remaining-time > div::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50px;
    bottom: 0;
    width: 1px;
    height: 100%;
    background-color: var(--primary);
}

.remaining-time span {
    color: var(--primary_text);
    font-weight: bold;
    padding-bottom: 3px;
}

/* @media (min-width: 768px) { */
.follow-us {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    flex-direction: column;
    margin-bottom: 0;
}
/* } */

.button-gorup {
    padding: 0 15px;
}

.button-gorup button {
    margin-bottom: 15px !important;
}

.code-wrapper svg {
    max-width: 100%;
}

section.section-lite.text-center.card.location-result {
    background: var(--form_color);
    border-radius: 4px;
    margin-bottom: 30px;
}

.margin-small .page-slug,
.margin-small .page-slug h2 {
    margin-bottom: 30px;
    margin-top: 30px;
}

.margin-small .page-slug h1 {
    margin-bottom: 30px;
    margin-top: 30px;
}

@media (min-width: 1199px) {
    .margin-small .page-slug,
    .margin-small .page-slug h2 {
        margin-top: 0;
        margin-bottom: 40px;
    }
}

.mt-n {
    margin-top: 30px;
}

.mb-n {
    margin-bottom: 30px;
}

.body-img {
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 40px);
}

.sm-h2 {
    font-size: 24px;
}

.multiline-page-slug .page-slug,
.multiline-page-slug h1 {
    margin-bottom: 0;
}

.page-slug p {
    color: var(--primary_text);
}

.faqs-content h3 {
    margin-bottom: 20px;
    margin-top: 40px;
}

.faqs-content h2 {
    font-size: 1.5em;
    margin-bottom: 20px;
    margin-top: 40px;
}

.faqs-content li {
    margin-bottom: 10px;
}

.text-align-left{
    text-align: left;
}

.faq-links {
    margin-top: 32px;
}

.header-title-image {
    padding: calc(var(--gap) * 2) 0;
    max-width: 225px;
}

.content-ready {
    animation-name: loading;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-delay: 1.5s;
    visibility: hidden;
    position: relative;
}

@keyframes loading {
    from {
        visibility: hidden;
    }
    to {
        visibility: visible;
    }
}

#loading-svg {
    margin: 0 auto;
}

.page-wrapper{
    position: relative;
}

.loading-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary);
    z-index: 1099;
    transition: none;
}
.loading-wrapper.done {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.6s ease 1s;
}



.solid-container,
.transparent-container {
    color: var(--primary_text);
    border-radius: 4px;
    backdrop-filter: blur(4px);
}

.solid-container {
    background-color: var(--primary);
}

.transparent-container {
    background-color: var(--primary_color_opacity);
}

.register-form input {
    font-size: 16px;
    padding: 0 15px;
}

.social-shares-wrapper {
    max-width: 525px;
}

.social-shares-wrapper > div {
    text-align: center;
}

.whatsAppBtn {
    width: auto;
    text-align: left;
    height: 42px;
    font-size:18px;
    background-color: var(--green);
    border-radius: 4px;
    box-shadow: inset -2px -2px 2px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    color: white;
}

.whatsAppBtn > div:first-child {
    width: 42px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.whatsAppBtn > div:first-child i {
    font-size: 18px;
}

.whatsAppBtn > div:nth-child(2) {
    flex: 1;
    text-align: left;
    font-weight: bold;
    margin-right: 15px;

}

.inline-list.list-social-media.social-shares {
    margin: 0;
}

.loading-spinner {
    width: 50px;
    height: 50px;
    border-top: 5px solid var(--primary_text_alpha_08);
    border-right: 5px solid var(--primary_text_alpha_08);
    border-bottom: 5px solid var(--primary_text_alpha_08);
    border-left: 5px solid var(--primary_text_alpha_5);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    -webkit-animation: spinner 700ms infinite linear;
    -moz-animation: spinner 700ms infinite linear;
    -ms-animation: spinner 700ms infinite linear;
    -o-animation: spinner 700ms infinite linear;
    animation: spinner 700ms infinite linear;
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.hidden {
    display: none !important;
}
