.appLinksSectionParent {
  margin-left: 15px;
  margin-right: 15px;
}

.mx-0{
  margin-left:0px !important;
  margin-right:0px !important;
}

.mt-0{
  margin-top: 0px !important;
}
