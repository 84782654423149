.sign-up-message > section.card {
    margin: 0 15px 20px;
    padding: 20px 15px;
}

.sign-up-message .btn {
    margin-bottom: 40px;
    max-width: 100%;
}

.sign-up-message ul.inline-list {
    margin: 0;
}
