.appLinksContainer {
}

.appLinksHidden{
  display: none;
}

.rowImages {
  display: flex;
  justify-content: center;
}
.getAppTitle {
  margin-bottom: 30px;
}

.linkImage {
  display: block;
}
.linkImage img {
  width: 158px;
  max-width: calc(100% - 20px);
}
