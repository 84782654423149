.register-form {
    position: relative;
    color: var(--primary_text);
    padding: 0 !important;
}

.description-box{
    display: block;
    padding-left: 45px;
    margin-top: -6px!important;
    margin-bottom: 24px;
}

.register-form::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 0;
    background-position-y: 130px;
    pointer-events: none;
}
.register-form form {
    margin: 0 auto;
    padding: 0px 25px   ;
}



.register-form p.form-error {
    margin: calc(var(--gap) * -0.5) 0 calc(var(--gap) * 0.5);
    font-size: 13px;
    height: 25px;
    text-align: left;
    padding-left: 10px;
    color: var(--primary_text);
}



.notification-box {
    /* background-color: var(--list-highlight); */
    padding: 10px;
    margin-bottom: 30px;
    border-radius: 5px;
}
.form-msg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.form-msg h3 {
    font-size: 30px;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
}
.input-wrapper textarea {
    resize: none;
    border-radius: 4px;
    width: 100%;
}
section.register-form form .input-wrapper {
    flex: 1 calc(50%);
    margin-top: 12px;
    margin-bottom: 12px;
}

section.register-form form .input-wrapper.input-block {
    text-align: center;
    margin-right: 0;
    flex-basis: 100%;
}
.checkboxes-wrapper .input-wrapper:first-child label {
    margin-bottom: 12px;
}

@media (max-width:1280px){

    .register-form form{
        padding: 0px 0px;
    }
}
