@font-face {
  font-family: 'HeinekenSerif18';
  src:  url('./HeinekenSerif18-Regular.woff') format('woff'),
        url('./HeinekenSerif18-Regular.woff') format('woff');
  font-weight: 400;
}
@font-face {
  font-family: 'HeinekenSerif18';
  src:  url('./HeinekenSerif18-Medium.woff') format('woff'),
        url('./HeinekenSerif18-Medium.woff') format('woff');
  font-weight: 500;
}
@font-face {
  font-family: 'HeinekenSerif18';
  src:  url('./HeinekenSerif18-Bold.woff') format('woff'),
        url('./HeinekenSerif18-Bold.woff') format('woff');
  font-weight: 700;
}
@font-face {
  font-family: 'HeinekenSerif18';
  src:  url('./HeinekenSerif18-ExtraBold.woff') format('woff'),
        url('./HeinekenSerif18-ExtraBold.woff') format('woff');
  font-weight: 900;
}
@font-face {
  font-family: 'HeinekenSerif18';
  src:  url('./HeinekenSerif18-Italic.woff') format('woff'),
        url('./HeinekenSerif18-Italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}