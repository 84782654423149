.fb-login-button {
    margin-bottom: 0;
}

.age-confirmation > form .btn {
    margin-top: 16px;
    margin-bottom: 0px;
}

.resend-email-link {
    margin-top: 16px;
}

p b {
    font-weight: 400;
    color: var(--form_color);
}
