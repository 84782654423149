.list-social-media {
    color: var(--primary_text);
}

ul.inline-list li button {
    display: block;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border-radius: 2px;
}

ul.inline-list li button i {
    color: var(--primary_text);
    font-size: 1.5em;
}

.social-shares-wrapper {
    margin-bottom: 15px;
}