.cookie-bar {
    box-shadow: 0px 0px 10px #000000;
    position: fixed !important;
    background-color: var(--primary);
    bottom: 0;
    width: calc(100% - 50px);
    max-width: 1150px;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 20px;
    border-radius: 8px 8px 0 0;
    display: flex;
    align-items: flex-start;
    z-index: 9999;
}

.cookie-bar .modal-close {
    background-color: var(--secondary);
    color: var(--secondary_text);
}

.cookie-bar .btn {
    flex: 0;
    padding: 10px 20px;
    min-width: 120px;
    border: none;
    margin-left: 30px;
}

@media (max-width: 1200px) {
    .cookie-bar {
        flex-direction: column;
        padding-right: 50px;
    }

    .cookie-bar .cookie-bar-content {
        margin-bottom: 30px;
    }

    .cookie-bar .btn {
        margin: 0;
    }

    .cookie-bar .modal-close {
        right: 10px;
        top: 10px;
        width: 30px;
        height: 30px;
        box-shadow: none;
    }
}
