.page-slug {
    margin-left: auto;
    margin-right: auto;
    /* max-width: 95%; */
    margin-bottom: 24px;
    color: var(--primary_text);
}

.page-slug:last-child {
    margin-bottom: 0;
}

.page-slug h1 {
    font-size: 28px;
    font-weight: bold;
    
    margin-bottom: 15px;
    color: var(--primary_text);
    font-family: var(--font_title);
}

.page-slug h2 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 0 !important;
    color: var(--primary_text);
    font-family:  var(--font_title);

}

.page-slug h3{
    color: var(--primary_text);
    font-family: var(--font_title);

}

.page-slug .narrow-info-container {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.page-slug .narrow-info-container p {
    margin-bottom: 0;
    text-transform: none;
    font-family: var(--font_body);
}

.page-slug.smaller h2 {
    font-size: 18px;
    font-weight: 500;
}
