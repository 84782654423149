.check-email {
    text-align: center;
    color: var(--primary_text);
    margin-top: -8px;
}

.location-container{
    padding-right: 16px;
    padding-left: 16px;
}

h3#second-text {
    position: relative;
    top: 6px;
}

h1 {
    text-align: center;
}

img.smart-obj {
    padding: 7px;
    position: relative;
    left: 3%;
    width: 33px;
    border-radius: 63px;
    border: 2px solid var(--primary_text);
}

.main-page-parent {
    display: flex;
    justify-content: space-around;
}

input[type='search'] {
    padding-right: 30px;
    background-repeat: no-repeat;
    background-size: 52px 53px;
    background-position: right center;
    border: none;
    width: 60%;
    border-radius: 25px;
    font-size: 20px;
}

.child-stripe-img {
    width: 104%;
}

.main-simple-page-parent {
    display: flex;
    justify-content: center;
    position: relative;
    top: 8px;
}

.main-page-child {
    display: flex;
    justify-content: space-around;
    position: relative;
    width: 100%;
    top: 46px;
}

.map-img {
    width: 85%;
}

.list-items-google-maps {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
}

.small-here {
    position: relative;
    left: 5%;
    color: var(--secondary_text);
    align-self: flex-start;
    top: -5px;
}

.list-item {
    color: var(--secondary_text);
    position: relative;
    align-self: flex-start;
    margin: 0px;
    left: 5%;
}

.stripe-list {
    align-self: flex-start;
    position: relative;
    left: 7%;
    width: 85.5%;
}

.list-parent-1 {
    background-color: var(--secondary_text);
}

.list-parent-2,
.list-parent-3,
.list-parent-4 {
    background-color: var(--form_color);
}

h2#first-item {
    /* color: var(--list-stripe); */
}

.list-parent-4 {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

input.thank-you-page-input::placeholder {
    color: var(--primary);
    position: relative;
    top: 0px;
}

.current-venue {
    margin-bottom: 50px;
}

.current-venue p {
    margin-bottom: 40px;
}

.transparent-wrapper-top .current-venue {
    margin-bottom: 0;
    padding: 0 15px;
}
